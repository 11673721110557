const forescapeGlobalKeys = [
  'forescapeStoreAvailability',
  'forescapeStoreBooking',
  'forescapeStoreSettings',
  'forescapeStoreCart',
  'forescapeStoreOrder',
  'forescapeStoreKiosk',
  'forescapeStoreWidgets',
  'forescapeStoreLayout',
  'forescapeStoreAnalytics',
  'forescapeStoreAccount',
  'forescapeStore',
  'forescapeCustom',
  'forescape-cart',
  'forescape-catalog',
  'forescapeRouter',
  'forescapeStorePay',
];

export const ifForescapeMounted = () => {
  return (
    typeof window !== 'undefined' &&
    forescapeGlobalKeys.some((key) => {
      return typeof window[key] !== 'undefined';
    })
  );
};
